import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppConstants from '../../constants/AppConstants';
import BrandAssociationItem from '../brandAssociationItem/BrandAssociationItem';
import { COLORS, FONT_SIZE } from '../../constants/styleConstants';
import titleCase from '../../utils.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 26,
    width: '100%',
  },
  accountContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  accountLabelWrapper: {
    padding: '0 15px 2px',
    borderRadius: 5,
    border: `0.5px solid ${COLORS.mischka}`,
  },
  accountLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_10),
    marginRight: 6,
  },
  itemsContainer: {
    marginTop: 25,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: 10,
    gridRowGap: 5,

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

const BrandAssociationSection = (props) => {
  const classes = useStyles();
  const noWrap = true;
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(Boolean(errorMessage));

  useEffect(() => {
    setShowError(Boolean(errorMessage));
  }, [errorMessage, setShowError]);

  const handleDialogueClose = () => {
    setShowError(false);
    setTimeout(() => {
      setErrorMessage(null);
    }, 500);
  };

  const {
    isAdmin,
    locatorEnabled,
    locatorAvailable,
    onLocatorSwitchChange,
    preferredMarketRetailerEnabled,
    preferredMarketRetailerDependenciesEnabled,
    preferredMarketRetailerAvailable,
    liveDataState,
    onLiveDataSwitchChange,
    fulfillment,
    fulfillmentState,
    onFulfillmentSwitchChange,
    data,
    isShopActive,
    isEnterprise,
    isFulfillmentPlus,
    addBrandForFulfillmentURL,
    isLiveDataActive,
    liveDataAvailable,
    isDealer,
    schedulerEnabled,
    schedulerAvailable,
    eventsEnabled,
    eventsAvailable,
    onSchedulerSwitchChange,
    onEventsSwitchChange,
    warrantyAvailable,
    warrantyEnabled,
    onWarrantySwitchChange,
  } = props;

  return (
    <>
      <Box className={classes.root}>
        <Typography noWrap={noWrap} variant="h2">
          Brand Association
        </Typography>
        {isEnterprise && (
          <Box className={classes.accountContainer}>
            <Typography noWrap={noWrap} className={classes.accountLabel}>
              Acct#
            </Typography>
            <Box className={classes.accountLabelWrapper}>
              <Typography noWrap={noWrap} variant="body1">
                {data.dealer_account_number || 'N/A'}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.itemsContainer}>
        <BrandAssociationItem
          title={`${titleCase(data.channel.retailer_relationship)} Status:`}
          status="requestDealerStatus"
          type="requestDealerStatus"
          data={data}
          isShopActive={isShopActive}
          isAdmin={isAdmin}
          isFulfillmentPlus={isFulfillmentPlus}
          addBrandForFulfillmentURL={addBrandForFulfillmentURL}
          setErrorMessage={setErrorMessage}
        />
        <BrandAssociationItem
          title="Live Data"
          status={
            isLiveDataActive
              ? AppConstants.ON_OFF_SWITCH
              : AppConstants.DISABLED
          }
          type="liveData"
          isAdmin={isAdmin}
          switch={liveDataState}
          onSwitchChange={onLiveDataSwitchChange}
          liveDataAvailable={liveDataAvailable}
          setErrorMessage={setErrorMessage}
          data={data}
        />
        <BrandAssociationItem
          title="Display on Locations Map"
          status={
            locatorAvailable
              ? AppConstants.ON_OFF_SWITCH
              : AppConstants.DISABLED
          }
          type="storeLocator"
          isAdmin={isAdmin}
          switch={locatorEnabled}
          data={data}
          onSwitchChange={onLocatorSwitchChange}
          locatorAvailable={locatorAvailable}
          setErrorMessage={setErrorMessage}
        />
        <BrandAssociationItem
          title="Receive Fulfillment Orders"
          type="fulfillment"
          status={
            fulfillment ? AppConstants.ON_OFF_SWITCH : AppConstants.DISABLED
          }
          switch={fulfillmentState}
          isAdmin={isAdmin}
          isDealer={isDealer}
          fulfillment={fulfillment}
          isFulfillmentPlus={isFulfillmentPlus}
          onSwitchChange={onFulfillmentSwitchChange}
          showToggle={isEnterprise || isFulfillmentPlus}
          addBrandForFulfillmentURL={addBrandForFulfillmentURL}
          setErrorMessage={setErrorMessage}
          data={data}
        />
        <BrandAssociationItem
          title="Preferred Market Retailer"
          type="preferredMarketRetailer"
          switch={preferredMarketRetailerEnabled && preferredMarketRetailerDependenciesEnabled}
          preferredMarketRetailerAvailable={preferredMarketRetailerAvailable}
        />
        <BrandAssociationItem
          title="Receive Service Appointments"
          status={
            schedulerAvailable
              ? AppConstants.ON_OFF_SWITCH
              : AppConstants.DISABLED
          }
          type="scheduler"
          isAdmin={isAdmin}
          switch={schedulerEnabled}
          onSwitchChange={onSchedulerSwitchChange}
          schedulerAvailable={schedulerAvailable}
          setErrorMessage={setErrorMessage}
          data={data}
        />
        <BrandAssociationItem
          title="Receive Test Ride Bookings"
          status={
            eventsAvailable
              ? AppConstants.ON_OFF_SWITCH
              : AppConstants.DISABLED
          }
          type="events"
          isAdmin={isAdmin}
          switch={eventsEnabled}
          onSwitchChange={onEventsSwitchChange}
          eventsAvailable={eventsAvailable}
          setErrorMessage={setErrorMessage}
          data={data}
        />
        <BrandAssociationItem
          title="Receive Warranty Appointments"
          status={
            warrantyAvailable
              ? AppConstants.ON_OFF_SWITCH
              : AppConstants.DISABLED
          }
          type="warranty"
          isAdmin={isAdmin}
          switch={warrantyEnabled}
          onSwitchChange={onWarrantySwitchChange}
          warrantyAvailable={warrantyAvailable}
          setErrorMessage={setErrorMessage}
          data={data}
        />
        <Dialog
          open={showError}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogueClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

BrandAssociationSection.propTypes = {
  isAdmin: PropTypes.bool,
  locatorEnabled: PropTypes.bool.isRequired,
  locatorAvailable: PropTypes.bool.isRequired,
  onLocatorSwitchChange: PropTypes.func,
  preferredMarketRetailerEnabled: PropTypes.bool.isRequired,
  preferredMarketRetailerAvailable: PropTypes.bool.isRequired,
  preferredMarketRetailerDependenciesEnabled: PropTypes.bool.isRequired,
  liveDataState: PropTypes.bool.isRequired,
  onLiveDataSwitchChange: PropTypes.func,
  fulfillment: PropTypes.bool.isRequired,
  fulfillmentState: PropTypes.bool.isRequired,
  onFulfillmentSwitchChange: PropTypes.func,
  isEnterprise: PropTypes.bool,
  isShopActive: PropTypes.bool,
  isFulfillmentPlus: PropTypes.bool,
  addBrandForFulfillmentURL: PropTypes.string,
  isLiveDataActive: PropTypes.bool,
  liveDataAvailable: PropTypes.bool,
  schedulerEnabled: PropTypes.bool.isRequired,
  schedulerAvailable: PropTypes.bool.isRequired,
  eventsEnabled: PropTypes.bool.isRequired,
  eventsAvailable: PropTypes.bool.isRequired,
  onSchedulerSwitchChange: PropTypes.func,
  onEventsSwitchChange: PropTypes.func,
  isDealer: PropTypes.bool,
  warrantyEnabled: PropTypes.bool,
  warrantyAvailable: PropTypes.bool,
  onWarrantySwitchChange: PropTypes.func,
  data: PropTypes.shape({
    dealer_account_number: PropTypes.string,
    channel: PropTypes.shape({
      retailer_relationship: PropTypes.string,
    }),
    channel_request: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

BrandAssociationSection.defaultProps = {
  onLiveDataSwitchChange: () => {},
  onFulfillmentSwitchChange: () => {},
  onLocatorSwitchChange: () => {},
  onSchedulerSwitchChange: () => {},
  onEventsSwitchChange: () => {},
  onWarrantySwitchChange: () => {},
  data: { dealer_account_number: '', channel: { retailer_relationship: 'dealer' } },
  isEnterprise: false,
  isShopActive: false,
  isFulfillmentPlus: false,
  addBrandForFulfillmentURL: '',
  isLiveDataActive: false,
  liveDataAvailable: false,
  isDealer: false,
  isAdmin: false,
  warrantyEnabled: false,
  warrantyAvailable: false,
};

export default BrandAssociationSection;
