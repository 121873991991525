import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Switch, Typography, withStyles } from '@material-ui/core';
import { COLORS } from '../../constants/styleConstants';

const GridRow = (props) => {
  const noWrap = true;
  const { index, data, classes } = props;

  const CustomSwitch = withStyles({
    switchBase: {
      color: COLORS.alto,
      '&$checked': {
        color: COLORS.caribbeanGreen,
      },
      '&$checked + $track': {
        backgroundColor: COLORS.caribbeanGreen,
        opacity: 0.5,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <Box className={`${classes.grid} ${classes.gridRow}`}>
      {
        data.map((item, colIndex) => (
          <Box key={`row_${index}_col_${colIndex + 1}`} className={item.wrapperClassName || ''}>
            {item.handleSwitchChange && item.switchState !== undefined && (
              <FormControlLabel
                value="start"
                control={
                  <CustomSwitch checked={item.switchState} onChange={item.handleSwitchChange} />
                }
                label={item.switchState ? 'On' : 'Off'}
                labelPlacement="start"
                style={{ marginTop: '-7px' }}
              />
            )}
            <Typography
              noWrap={noWrap}
              className={item.labelClassName || ''}
            >
              {item.label}
            </Typography>
          </Box>
        ))
      }
    </Box>
  );
};

GridRow.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    switchState: PropTypes.bool,
    handleSwitchChange: PropTypes.func,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
  })),
  classes: PropTypes.shape({
    grid: PropTypes.string,
    gridRow: PropTypes.string,
  }).isRequired,
};

GridRow.defaultProps = {
  data: [],
};

export default GridRow;
