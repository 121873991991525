import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  withStyles,
  Button,
} from '@material-ui/core';
import ContentListItem from '../contentListItem/ContentListItem';
import { COLORS, FONT_SIZE, FONT_STYLE } from '../../constants/styleConstants';
import AppConstants from '../../constants/AppConstants';
import MATERIAL_UI_CONSTANTS from '../../constants/materialUIConstants';
import requestDealerStatus from '../../services/requestDealerStatus';
import titleCase from '../../utils.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: 50,
    borderRadius: 7,
    border: `1px solid ${COLORS.mischka}`,
    display: 'flex',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: 20,
  },
  imgContainer: {
    minWidth: 40,
  },
  img: {
    width: 'auto',
    height: 30,
  },
  title: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    },
  },
  disabledContainer: {
    padding: '3px 18px',
    borderRadius: 4,
  },
  disabledLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_12),
    color: COLORS.black,
    opacity: 0.66,
  },
  requestDealerStatusLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    fontWeight: FONT_STYLE.bold,
    color: COLORS.black,
    backgroundColor: COLORS.supernova,
  },
  statusLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    fontWeight: FONT_STYLE.bold,
    color: COLORS.black,
  },
}));

const BrandAssociationItem = (props) => {
  const classes = useStyles();
  const {
    img,
    title,
    status,
    switch: switchProp,
    onSwitchChange,
    data,
    isShopActive,
    isAdmin,
    isFulfillmentPlus,
    showToggle,
    addBrandForFulfillmentURL,
    type,
    isDealer,
    locatorAvailable,
    preferredMarketRetailerAvailable,
    fulfillment,
    liveDataAvailable,
    setErrorMessage,
    schedulerAvailable,
    eventsAvailable,
    warrantyAvailable,
  } = props;
  const [switchState, setSwitchState] = useState(switchProp);
  const [updatedStatus, setUpdatedStatus] = useState(data?.channel_request?.status);
  const noWrap = true;

  const CustomSwitch = withStyles({
    switchBase: {
      color: COLORS.alto,
      '&$checked': {
        color: COLORS.caribbeanGreen,
      },
      '&$checked + $track': {
        backgroundColor: COLORS.caribbeanGreen,
        opacity: 0.5,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleRequestClick = async () => {
    const retailerRelationship = data.channel.retailer_relationship || 'Dealer';

    if (!isAdmin) {
      setErrorMessage('Contact your shop\'s administrator to take action');
      return;
    }
    if (!isShopActive) {
      setErrorMessage(
        `You must have at least one active shop to request ${titleCase(retailerRelationship)} status. Please contact Beeline Connect Support if you have activation questions.`,
      );
      return;
    }
    if (isDealer) {
      setErrorMessage(`Already a ${titleCase(retailerRelationship)}`);
      return;
    }
    const result = await requestDealerStatus(data.channel.id);
    setUpdatedStatus(result.status);
  };

  const DisabledItemStatus = () => (
    <Box className={classes.disabledContainer} bgcolor={COLORS.alto}>
      <Typography
        noWrap={noWrap}
        className={classes.disabledLabel}
      >
        N/A
      </Typography>
    </Box>
  );

  const RequestDealerItemStatus = () => {
    const retailerRelationship = data.channel.retailer_relationship || 'Dealer';

    if (
      (updatedStatus === 'approved' && !data.is_dealer)
      || updatedStatus === 'pending'
    ) {
      return (
        <Typography noWrap={noWrap} className={classes.statusLabel}>
          Request Pending
        </Typography>
      );
    }

    // request dealer status
    if (
      (!data.channel_request && data.channel.is_enterprise)
      || (updatedStatus === 'denied')
    ) {
      return (
        <Button
          variant={MATERIAL_UI_CONSTANTS.contained}
          className={classes.requestDealerStatusLabel}
          onClick={handleRequestClick}
        >
          {`Request ${titleCase(retailerRelationship)} Status`}
        </Button>
      );
    }

    if (data.channel && !data.channel.is_enterprise) {
      return (
        DisabledItemStatus()
      );
    }
    if (data.channel && !data.channel_request && data.channel.is_enterprise) {
      return (
        <Typography
          noWrap={noWrap}
          className={classes.disabledLabel}
        >
          {`Not a ${titleCase(retailerRelationship)}`}
        </Typography>
      );
    }

    if (!data.channel_request && isFulfillmentPlus) {
      return (
        <Typography
          noWrap={noWrap}
          className={classes.statusLabel}
        >
          Active
        </Typography>
      );
    }

    // display status
    if (
      data.channel_request
      && data.is_dealer
      && updatedStatus === 'approved'
    ) {
      return (
        <Typography noWrap={noWrap} className={classes.statusLabel}>
          Approved
        </Typography>
      );
    }

    if (!data.channel.is_enterprise) {
      return (
        <Box className={classes.disabledContainer} bgcolor={COLORS.alto}>
          <Typography
            noWrap={noWrap}
            className={classes.disabledLabel}
          >
            N/A
          </Typography>
        </Box>
      );
    }

    // Request Pending

    return null;
  };

  // eslint-disable-next-line consistent-return
  const handleSwitchChange = (event, isFulfillmentToggle) => {
    if (!data.is_dealer && (isFulfillmentToggle && !isFulfillmentPlus) && event.target.checked) {
      // eslint-disable-next-line react/prop-types
      return setErrorMessage(`You must be an approved Dealer/service center for ${data?.channel?.name} to turn on ${title}.`);
    }
    setSwitchState(event.target.checked);

    if (onSwitchChange) {
      onSwitchChange(event.target.checked);
    }
  };

  const handleFulfillmentSwitchChange = (event) => handleSwitchChange(event, true);

  const hideImg = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.style.display = 'none';
  };

  const getFulfillmentLabel = () => {
    if (!fulfillment) {
      return DisabledItemStatus();
    }
    if ((isFulfillmentPlus) || (!isFulfillmentPlus && isDealer)) {
      if (isAdmin) {
        return (
          <FormControlLabel
            value="start"
            control={
              <CustomSwitch checked={switchState} onChange={handleFulfillmentSwitchChange} />
            }
            label={switchState ? 'On' : 'Off'}
            labelPlacement="start"
          />
        );
      }
      return <ContentListItem icon={switchState ? 'tick' : 'cross'} content="" direction={AppConstants.RIGHT} />;
    }

    if (!isDealer && isAdmin) {
      return (
        <Button
          variant={MATERIAL_UI_CONSTANTS.contained}
          className={classes.requestDealerStatusLabel}
          onClick={() => {
            window.top.location.href = addBrandForFulfillmentURL;
          }}
        >
          Activate Premium
        </Button>
      );
    }

    if (!isDealer && !isAdmin) {
      return (
        <Box className={classes.disabledContainer} bgcolor={COLORS.alto}>
          <Typography
            noWrap={noWrap}
            className={classes.disabledLabel}
          >
            Admin must activate Premium
          </Typography>
        </Box>
      );
    }
    return <></>;
  };

  const getLivaDataLabel = () => {
    if (!liveDataAvailable) {
      return DisabledItemStatus();
    }
    if (status === AppConstants.ON_OFF_SWITCH && isAdmin) {
      return (
        <FormControlLabel
          value="start"
          control={<CustomSwitch checked={switchState} onChange={handleSwitchChange} />}
          label={switchState ? 'On' : 'Off'}
          labelPlacement="start"
        />
      );
    }

    if (status === AppConstants.ON_OFF_SWITCH && !isAdmin) {
      return <ContentListItem icon={switchState ? 'tick' : 'cross'} content="" direction={AppConstants.RIGHT} />;
    }

    if (status !== AppConstants.ON_OFF_SWITCH && isAdmin) {
      return (
        <Button
          variant={MATERIAL_UI_CONSTANTS.contained}
          className={classes.requestDealerStatusLabel}
          onClick={() => {
            window.open(`${process.env.REACT_APP_CORE_URL}dashboard/v2/live_data_program`, '_top');
          }}
        >
          Activate Live Data
        </Button>
      );
    }

    if (status !== AppConstants.ON_OFF_SWITCH) {
      return (
        <Box className={classes.disabledContainer} bgcolor={COLORS.alto}>
          <Typography
            noWrap={noWrap}
            className={classes.disabledLabel}
          >
            Admin must activate live data
          </Typography>
        </Box>
      );
    }
    return <></>;
  };

  const getStoreLocatorLabel = () => {
    if (!locatorAvailable) {
      return DisabledItemStatus();
    }

    if (status === AppConstants.ON_OFF_SWITCH && isAdmin) {
      return (
        <FormControlLabel
          value="start"
          control={<CustomSwitch checked={switchState} onChange={handleSwitchChange} />}
          label={switchState ? 'On' : 'Off'}
          labelPlacement="start"
        />
      );
    }

    if (status === AppConstants.ON_OFF_SWITCH && !isAdmin) {
      return <ContentListItem icon={switchState ? 'tick' : 'cross'} content="" direction={AppConstants.RIGHT} />;
    }

    return <></>;
  };

  const getSchedulerLabel = () => {
    if (!schedulerAvailable) {
      return DisabledItemStatus();
    }

    if (status === AppConstants.ON_OFF_SWITCH && isAdmin) {
      return (
        <FormControlLabel
          value="start"
          control={<CustomSwitch checked={switchState} onChange={handleSwitchChange} />}
          label={switchState ? 'On' : 'Off'}
          labelPlacement="start"
        />
      );
    }

    if (status === AppConstants.ON_OFF_SWITCH && !isAdmin) {
      return <ContentListItem icon={switchState ? 'tick' : 'cross'} content="" direction={AppConstants.RIGHT} />;
    }

    return <></>;
  };

  const getEventsLabel = () => {
    if (!eventsAvailable) {
      return DisabledItemStatus();
    }

    if (status === AppConstants.ON_OFF_SWITCH && isAdmin) {
      return (
        <FormControlLabel
          value="start"
          control={<CustomSwitch checked={switchState} onChange={handleSwitchChange} />}
          label={switchState ? 'On' : 'Off'}
          labelPlacement="start"
        />
      );
    }

    if (status === AppConstants.ON_OFF_SWITCH && !isAdmin) {
      return <ContentListItem icon={switchState ? 'tick' : 'cross'} content="" direction={AppConstants.RIGHT} />;
    }

    return <></>;
  };

  const getWarrantyLabel = () => {
    if (!warrantyAvailable) {
      return DisabledItemStatus();
    }

    if (status === AppConstants.ON_OFF_SWITCH && isAdmin) {
      return (
        <FormControlLabel
          value="start"
          control={<CustomSwitch checked={switchState} onChange={handleSwitchChange} />}
          label={switchState ? 'On' : 'Off'}
          labelPlacement="start"
        />
      );
    }

    if (status === AppConstants.ON_OFF_SWITCH && !isAdmin) {
      return <ContentListItem icon={switchState ? 'tick' : 'cross'} content="" direction={AppConstants.RIGHT} />;
    }

    return <></>;
  };

  const getLabel = () => {
    // live data logic
    if (type === 'liveData') {
      return getLivaDataLabel();
    }

    if (type === 'fulfillment') {
      return getFulfillmentLabel();
    }

    if (type === 'storeLocator') {
      return getStoreLocatorLabel();
    }

    if (type === 'scheduler') {
      return getSchedulerLabel();
    }

    if (type === 'events') {
      return getEventsLabel();
    }

    if (type === 'warranty') {
      return getWarrantyLabel();
    }

    if (type === 'storeLocator' && locatorAvailable) {
      return <ContentListItem icon={switchState ? 'tick' : 'cross'} content="" direction={AppConstants.RIGHT} />;
    }

    if (type === 'preferredMarketRetailer' && preferredMarketRetailerAvailable) {
      return <ContentListItem icon={switchState ? 'tick' : 'cross'} content="" direction={AppConstants.RIGHT} />;
    }

    if (type === 'preferredMarketRetailer' && !preferredMarketRetailerAvailable) {
      return (
        <Box className={classes.disabledContainer} bgcolor={COLORS.alto}>
          <Typography
            noWrap={noWrap}
            className={classes.disabledLabel}
          >
            N/A
          </Typography>
        </Box>
      );
    }

    if (status === 'requestDealerStatus') {
      return <Box>{RequestDealerItemStatus()}</Box>;
    }

    if (status === AppConstants.ENABLED) {
      return <ContentListItem icon="tick" content="Enabled" direction={AppConstants.RIGHT} />;
    }

    if (status === AppConstants.ON_OFF_SWITCH) {
      if (title === 'Fulfillment' && showToggle === false) {
        return (
          <Button
            variant={MATERIAL_UI_CONSTANTS.contained}
            className={classes.requestDealerStatusLabel}
            onClick={() => {
              window.open(addBrandForFulfillmentURL, '_top');
            }}
          >
            Enable Fulfillment+
          </Button>
        );
      }

      return (
        <FormControlLabel
          value="start"
          control={<CustomSwitch checked={switchState} onChange={handleSwitchChange} />}
          label={switchState ? 'On' : 'Off'}
          labelPlacement="start"
        />
      );
    }

    return <DisabledItemStatus />;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.imgContainer}>
        {img && <img src={img} alt={title} className={classes.img} onError={hideImg} />}
      </Box>
      <Box className={classes.contentContainer}>
        <Typography
          noWrap={noWrap}
          variant="body1"
          className={classes.title}
          title={title}
        >
          {title}
        </Typography>
        {
          getLabel()
        }
      </Box>
    </Box>
  );
};

BrandAssociationItem.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  switch: PropTypes.string,
  type: PropTypes.string,
  onSwitchChange: PropTypes.func,
  isAdmin: PropTypes.bool,
  isShopActive: PropTypes.bool,
  isFulfillmentPlus: PropTypes.bool,
  data: PropTypes.shape({
    channel: PropTypes.shape({
      id: PropTypes.string,
      is_enterprise: PropTypes.bool,
      retailer_relationship: PropTypes.string,
    }),
    channel_request: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
    }),
    is_dealer: PropTypes.bool,
  }),
  showToggle: PropTypes.bool,
  isDealer: PropTypes.bool,
  locatorAvailable: PropTypes.bool,
  schedulerAvailable: PropTypes.bool,
  eventsAvailable: PropTypes.bool,
  preferredMarketRetailerAvailable: PropTypes.bool,
  liveDataAvailable: PropTypes.bool,
  fulfillment: PropTypes.bool,
  addBrandForFulfillmentURL: PropTypes.string,
  setErrorMessage: PropTypes.func,
  warrantyAvailable: PropTypes.bool,
};

BrandAssociationItem.defaultProps = {
  img: '',
  title: '',
  status: AppConstants.DISABLED,
  switch: false,
  onSwitchChange: () => {},
  data: {},
  isAdmin: false,
  isShopActive: false,
  isFulfillmentPlus: false,
  showToggle: false,
  liveDataAvailable: false,
  fulfillment: false,
  addBrandForFulfillmentURL: '',
  type: '',
  isDealer: false,
  locatorAvailable: false,
  schedulerAvailable: false,
  eventsAvailable: false,
  preferredMarketRetailerAvailable: false,
  warrantyAvailable: false,
  setErrorMessage: () => true,
};

export default BrandAssociationItem;
