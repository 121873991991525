export default {
  GET_CHANNEL_DOCUMENTS: 'get_channel_documents',
  GET_CHANNEL_COMPANY_ASSOCIATIONS: 'get_channel_company_associations',
  ENABLE_LIVE_DATA: 'enable_live_data',
  DISABLE_LIVE_DATA: 'disable_live_data',
  ENABLE_FULFILLMENT: 'enable_fulfillment',
  DISABLE_FULFILLMENT: 'disable_fulfillment',
  ENABLE_LOCATOR: 'enable_locator',
  DISABLE_LOCATOR: 'disable_locator',
  ENABLE_SCHEDULER: 'enable_scheduler',
  DISABLE_SCHEDULER: 'disable_scheduler',
  ENABLE_EVENTS: 'enable_events',
  DISABLE_EVENTS: 'disable_events',
  ENABLE_WARRANTY: 'enable_warranty',
  DISABLE_WARRANTY: 'disable_warranty',
  REQUEST_DEALER_STATUS: 'request_dealer_status',
  ADD_BRAND_FOR_FULFILLMENT_URL: 'add_brand_for_fulfillment_url',
  GET_BADGES: 'get_badges',
  GET_LOCATIONS: 'get_locations',
  GET_SHOP_CHANNEL_ATTRIBUTES: 'get_shop_channel_attributes',
  ENABLE_FULFILLMENT_BY_TAG: 'enable_fulfillment_by_tag',
  DISABLE_FULFILLMENT_BY_TAG: 'disable_fulfillment_by_tag',
};
